.m-6 {
  margin: 6rem;
}

.mt-6 {
  margin-top: 6rem;
}

.mb-6 {
  margin-bottom: 6rem;
}

.ms-6 {
  margin-left: 6rem;
}

.me-6 {
  margin-right: 6rem;
}

.mx-6 {
  margin-right: 6rem;
  margin-left: 6rem;
}

.my-6 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.p-6 {
  padding: 6rem;
}

.pt-6 {
  padding-top: 6rem;
}

.pb-6 {
  padding-bottom: 6rem;
}

.ps-6 {
  padding-left: 6rem;
}

.pe-6 {
  padding-right: 6rem;
}

.px-6 {
  padding-right: 6rem;
  padding-left: 6rem;
}

.py-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.m-7 {
  margin: 7rem;
}

.mt-7 {
  margin-top: 7rem;
}

.mb-7 {
  margin-bottom: 7rem;
}

.ms-7 {
  margin-left: 7rem;
}

.me-7 {
  margin-right: 7rem;
}

.mx-7 {
  margin-right: 7rem;
  margin-left: 7rem;
}

.my-7 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.p-7 {
  padding: 7rem;
}

.pt-7 {
  padding-top: 7rem;
}

.pb-7 {
  padding-bottom: 7rem;
}

.ps-7 {
  padding-left: 7rem;
}

.pe-7 {
  padding-right: 7rem;
}

.px-7 {
  padding-right: 7rem;
  padding-left: 7rem;
}

.py-7 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.m-8 {
  margin: 8rem;
}

.mt-8 {
  margin-top: 8rem;
}

.mb-8 {
  margin-bottom: 8rem;
}

.ms-8 {
  margin-left: 8rem;
}

.me-8 {
  margin-right: 8rem;
}

.mx-8 {
  margin-right: 8rem;
  margin-left: 8rem;
}

.my-8 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.p-8 {
  padding: 8rem;
}

.pt-8 {
  padding-top: 8rem;
}

.pb-8 {
  padding-bottom: 8rem;
}

.ps-8 {
  padding-left: 8rem;
}

.pe-8 {
  padding-right: 8rem;
}

.px-8 {
  padding-right: 8rem;
  padding-left: 8rem;
}

.py-8 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.m-9 {
  margin: 9rem;
}

.mt-9 {
  margin-top: 9rem;
}

.mb-9 {
  margin-bottom: 9rem;
}

.ms-9 {
  margin-left: 9rem;
}

.me-9 {
  margin-right: 9rem;
}

.mx-9 {
  margin-right: 9rem;
  margin-left: 9rem;
}

.my-9 {
  margin-top: 9rem;
  margin-bottom: 9rem;
}

.p-9 {
  padding: 9rem;
}

.pt-9 {
  padding-top: 9rem;
}

.pb-9 {
  padding-bottom: 9rem;
}

.ps-9 {
  padding-left: 9rem;
}

.pe-9 {
  padding-right: 9rem;
}

.px-9 {
  padding-right: 9rem;
  padding-left: 9rem;
}

.py-9 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.m-10 {
  margin: 10rem;
}

.mt-10 {
  margin-top: 10rem;
}

.mb-10 {
  margin-bottom: 10rem;
}

.ms-10 {
  margin-left: 10rem;
}

.me-10 {
  margin-right: 10rem;
}

.mx-10 {
  margin-right: 10rem;
  margin-left: 10rem;
}

.my-10 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.p-10 {
  padding: 10rem;
}

.pt-10 {
  padding-top: 10rem;
}

.pb-10 {
  padding-bottom: 10rem;
}

.ps-10 {
  padding-left: 10rem;
}

.pe-10 {
  padding-right: 10rem;
}

.px-10 {
  padding-right: 10rem;
  padding-left: 10rem;
}

.py-10 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.m-11 {
  margin: 11rem;
}

.mt-11 {
  margin-top: 11rem;
}

.mb-11 {
  margin-bottom: 11rem;
}

.ms-11 {
  margin-left: 11rem;
}

.me-11 {
  margin-right: 11rem;
}

.mx-11 {
  margin-right: 11rem;
  margin-left: 11rem;
}

.my-11 {
  margin-top: 11rem;
  margin-bottom: 11rem;
}

.p-11 {
  padding: 11rem;
}

.pt-11 {
  padding-top: 11rem;
}

.pb-11 {
  padding-bottom: 11rem;
}

.ps-11 {
  padding-left: 11rem;
}

.pe-11 {
  padding-right: 11rem;
}

.px-11 {
  padding-right: 11rem;
  padding-left: 11rem;
}

.py-11 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.m-12 {
  margin: 12rem;
}

.mt-12 {
  margin-top: 12rem;
}

.mb-12 {
  margin-bottom: 12rem;
}

.ms-12 {
  margin-left: 12rem;
}

.me-12 {
  margin-right: 12rem;
}

.mx-12 {
  margin-right: 12rem;
  margin-left: 12rem;
}

.my-12 {
  margin-top: 12rem;
  margin-bottom: 12rem;
}

.p-12 {
  padding: 12rem;
}

.pt-12 {
  padding-top: 12rem;
}

.pb-12 {
  padding-bottom: 12rem;
}

.ps-12 {
  padding-left: 12rem;
}

.pe-12 {
  padding-right: 12rem;
}

.px-12 {
  padding-right: 12rem;
  padding-left: 12rem;
}

.py-12 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.m-14 {
  margin: 14rem;
}

.mt-14 {
  margin-top: 14rem;
}

.mb-14 {
  margin-bottom: 14rem;
}

.ms-14 {
  margin-left: 14rem;
}

.me-14 {
  margin-right: 14rem;
}

.mx-14 {
  margin-right: 14rem;
  margin-left: 14rem;
}

.my-14 {
  margin-top: 14rem;
  margin-bottom: 14rem;
}

.p-14 {
  padding: 14rem;
}

.pt-14 {
  padding-top: 14rem;
}

.pb-14 {
  padding-bottom: 14rem;
}

.ps-14 {
  padding-left: 14rem;
}

.pe-14 {
  padding-right: 14rem;
}

.px-14 {
  padding-right: 14rem;
  padding-left: 14rem;
}

.py-14 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.m-16 {
  margin: 16rem;
}

.mt-16 {
  margin-top: 16rem;
}

.mb-16 {
  margin-bottom: 16rem;
}

.ms-16 {
  margin-left: 16rem;
}

.me-16 {
  margin-right: 16rem;
}

.mx-16 {
  margin-right: 16rem;
  margin-left: 16rem;
}

.my-16 {
  margin-top: 16rem;
  margin-bottom: 16rem;
}

.p-16 {
  padding: 16rem;
}

.pt-16 {
  padding-top: 16rem;
}

.pb-16 {
  padding-bottom: 16rem;
}

.ps-16 {
  padding-left: 16rem;
}

.pe-16 {
  padding-right: 16rem;
}

.px-16 {
  padding-right: 16rem;
  padding-left: 16rem;
}

.py-16 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.m-18 {
  margin: 18rem;
}

.mt-18 {
  margin-top: 18rem;
}

.mb-18 {
  margin-bottom: 18rem;
}

.ms-18 {
  margin-left: 18rem;
}

.me-18 {
  margin-right: 18rem;
}

.mx-18 {
  margin-right: 18rem;
  margin-left: 18rem;
}

.my-18 {
  margin-top: 18rem;
  margin-bottom: 18rem;
}

.p-18 {
  padding: 18rem;
}

.pt-18 {
  padding-top: 18rem;
}

.pb-18 {
  padding-bottom: 18rem;
}

.ps-18 {
  padding-left: 18rem;
}

.pe-18 {
  padding-right: 18rem;
}

.px-18 {
  padding-right: 18rem;
  padding-left: 18rem;
}

.py-18 {
  padding-top: 18rem;
  padding-bottom: 18rem;
}

.m-20 {
  margin: 20rem;
}

.mt-20 {
  margin-top: 20rem;
}

.mb-20 {
  margin-bottom: 20rem;
}

.ms-20 {
  margin-left: 20rem;
}

.me-20 {
  margin-right: 20rem;
}

.mx-20 {
  margin-right: 20rem;
  margin-left: 20rem;
}

.my-20 {
  margin-top: 20rem;
  margin-bottom: 20rem;
}

.p-20 {
  padding: 20rem;
}

.pt-20 {
  padding-top: 20rem;
}

.pb-20 {
  padding-bottom: 20rem;
}

.ps-20 {
  padding-left: 20rem;
}

.pe-20 {
  padding-right: 20rem;
}

.px-20 {
  padding-right: 20rem;
  padding-left: 20rem;
}

.py-20 {
  padding-top: 20rem;
  padding-bottom: 20rem;
}

.m-22 {
  margin: 22rem;
}

.mt-22 {
  margin-top: 22rem;
}

.mb-22 {
  margin-bottom: 22rem;
}

.ms-22 {
  margin-left: 22rem;
}

.me-22 {
  margin-right: 22rem;
}

.mx-22 {
  margin-right: 22rem;
  margin-left: 22rem;
}

.my-22 {
  margin-top: 22rem;
  margin-bottom: 22rem;
}

.p-22 {
  padding: 22rem;
}

.pt-22 {
  padding-top: 22rem;
}

.pb-22 {
  padding-bottom: 22rem;
}

.ps-22 {
  padding-left: 22rem;
}

.pe-22 {
  padding-right: 22rem;
}

.px-22 {
  padding-right: 22rem;
  padding-left: 22rem;
}

.py-22 {
  padding-top: 22rem;
  padding-bottom: 22rem;
}

.m-24 {
  margin: 24rem;
}

.mt-24 {
  margin-top: 24rem;
}

.mb-24 {
  margin-bottom: 24rem;
}

.ms-24 {
  margin-left: 24rem;
}

.me-24 {
  margin-right: 24rem;
}

.mx-24 {
  margin-right: 24rem;
  margin-left: 24rem;
}

.my-24 {
  margin-top: 24rem;
  margin-bottom: 24rem;
}

.p-24 {
  padding: 24rem;
}

.pt-24 {
  padding-top: 24rem;
}

.pb-24 {
  padding-bottom: 24rem;
}

.ps-24 {
  padding-left: 24rem;
}

.pe-24 {
  padding-right: 24rem;
}

.px-24 {
  padding-right: 24rem;
  padding-left: 24rem;
}

.py-24 {
  padding-top: 24rem;
  padding-bottom: 24rem;
}/*# sourceMappingURL=utilities.css.map */